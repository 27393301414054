import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row } from "react-bootstrap"
import * as garmentStyles from "../css/garment.module.css"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import chevronDoubleRight from '@iconify/icons-mdi-light/chevron-double-right';
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Bgcat from "../components/bgcat"
import { rhythm } from '../utils/typography'
import Seo from "../components/seo"

const Seotitle = "สินค้าทั้งหมด"
const SeoDecription = "สินค้าทั้งหมด เกี่ยวกับเส้นด้าย ที่เป็นแรงบันดาลใจ ในการให้บริการ เราเป็นผู้ผลิตรายใหญ่และด้วยประสบการณ์ในวงการสิ่งทอที่ยาวนาน 60 ปี"

export default class ProductCatagories extends React.Component {
    render() {
      const posts = this.props.data.allMarkdownRemark.edges
      const { catcurrentPage, catagoriesnumPages } = this.props.pageContext
      const isFirst = catcurrentPage === 1
      const isLast = catcurrentPage === catagoriesnumPages
      const prevPage = catcurrentPage - 1 === 1 ? "/categories/" : "/categories/" + (catcurrentPage - 1).toString()
      const nextPage = "/categories/" + (catcurrentPage + 1).toString()
      const url = "/categories/"
    return (
    <Containers>
          <Seo 
           title={Seotitle}
           description={SeoDecription}
           pathname={url}
           />
      <Headertop />
      <Header />
      <Bgcat />
      <div className={garmentStyles.herob}>
      <Container fluid="xl">
              <Row>
                  <Col><p><Link to="/">Home</Link>  <Icon icon={chevronDoubleRight} />  Product</p></Col>
              </Row>
      </Container>
      </div>
      <div className={garmentStyles.herosection}>
          <Container fluid="xl">
              <Row>
                <Col md={3}>
                <div className={garmentStyles.categories}>
                  <h3>หมวดหมู่สินค้า</h3>
                  <Link to="/categories/"><p className={garmentStyles.pmenuactive}>สินค้าทั้งหมด</p></Link>
                  <Link to="/garment/"><p className={garmentStyles.pmenu}>งานการ์เม้นท์</p></Link>
                  <Link to="/sew-a-sack/"><p className={garmentStyles.pmenu}>ด้ายเย็บกระสอบ</p></Link>
                  <Link to="/industrial-thread/"><p className={garmentStyles.pmenu}>ด้ายอุตสหกรรม</p></Link>
                  <Link to="/product-import/"><p className={garmentStyles.pmenu}>สินค้านำเข้า</p></Link>
                  <Link to="/other-products/"><p className={garmentStyles.pmenu}>สินค้าอื่นๆ</p></Link>
                </div>
                </Col>
                <Col md={9}>
                  <Containers>
                    <Row>
                      {posts.map(({ node }) => {
                        const title = node.frontmatter.title || node.fields.slug
                        const link = node.fields.slug
                        const image = node.frontmatter.featuredImage.childImageSharp.fluid
                        return <Col xs={6} md={4} key={node.id} className={homeStyles.colproduct}>
                          <Link to={link}>
                            <Img fluid={image} className={homeStyles.imgproduct} />
                            <h3>{title}</h3>
                          </Link>
                        </Col>})}
                    </Row>
                  </Containers>
                  <Containers>
                      <Row>
                          <Col>
                            <ul
                              style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  alignItems: 'center',
                                  listStyle: 'none',
                                  paddingTop: 30,
                                  marginLeft: 0,
                                  justifyContent: 'center',
                                  }}
                            >
                                {!isFirst && (
                                    <Link to={prevPage} rel="prev">
                                    ← Previous
                                    </Link>
                                )}
                                {Array.from({ length: catagoriesnumPages }, (_, i) => (
                                    <li
                                    key={`pagination-number${i + 1}`}
                                    style={{
                                        margin: 0,
                                    }}
                                    >
                                    <Link
                                        to={`/categories/${i === 0 ? '' : i + 1}`}
                                        style={{
                                        padding: rhythm(1 / 4),
                                        paddingLeft: 20,
                                        paddingRight: 20,
                                        borderRadius: 6,
                                        textDecoration: 'none',
                                        color: i + 1 === catcurrentPage ? '#ffffff' : '',
                                        background: i + 1 === catcurrentPage ? '#050248' : '',
                                        }}
                                    >
                                        {i + 1}
                                    </Link>
                                    </li>
                                ))}
                                {!isLast && (
                                    <Link to={nextPage} rel="next">
                                    Next →
                                    </Link>
                                )}
                                </ul>
                          </Col>
                      </Row>
                  </Containers>
                </Col>
              </Row>
          </Container>
      </div>
        <Container className="pb-5">
          <Row>
            <Col md={12}>
              <p>
                สินค้าน่าสนใจจากอุตสาหกรรมสิ่งทอ การผสมผสานเทคโนโลยีและความคิดสร้างสรรค์
              </p>
              <p>ในโลกที่เติบโตและเปลี่ยนแปลงอย่างรวดเร็ว อุตสาหกรรมสิ่งทอก็ได้รับการพัฒนาที่มีประสิทธิภาพและนวัตกรรม นี่คือสินค้าที่น่าสนใจจากอุตสาหกรรมสิ่งทอที่ผสมผสานเทคโนโลยีและความคิดสร้างสรรค์</p>
              <ol>
                <li>ความเชี่ยวชาญในอุปกรณ์ตัดเย็บ เราเป็นผู้เชี่ยวชาญในการผลิตและจำหน่ายอุปกรณ์ตัดเย็บทุกชนิดที่ตอบโจทย์ความต้องการของธุรกิจอุตสาหกรรมที่คุณกำลังดำเนิน. ไม่ว่าจะเป็นกรรไกรตัด, เครื่องเย็บ, หรืออุปกรณ์อื่น ๆ ที่เกี่ยวข้อง, เรามีความเข้าใจในความสำคัญของอุปกรณ์ที่มีคุณภาพสูงเพื่อให้กระบวนการผลิตของคุณเป็นไปอย่างมีประสิทธิภาพ.</li>
                <li>เส้นด้ายอุตสาหกรรมทุกชนิด เรานำเข้าและจำหน่ายเส้นด้ายอุตสาหกรรมทุกชนิดที่ตอบสนองต่อความต้องการของลูกค้า. ไม่ว่าจะเป็นเส้นด้ายที่มีความแข็งแรงสูง, มีความยืดหยุ่น, หรือเส้นด้ายพิเศษที่ใช้ในการผลิตผลิตภัณฑ์เฉพาะ, เราสามารถให้คำปรึกษาและจัดหาเส้นด้ายที่เหมาะสมที่สุดสำหรับงานของคุณ.</li>
                <li>ความมั่นใจในคุณภาพ คุณภาพของผลิตภัณฑ์เป็นสิ่งที่เราให้ความสำคัญมาก. ทุกขั้นตอนของกระบวนการผลิตถูกควบคุมอย่างเข้มงวดเพื่อให้แน่ใจว่าสินค้าที่เราผลิตและจำหน่ายมีมาตรฐานคุณภาพสูงที่สุด.</li>
                <li>บริการลูกค้าที่ประทับใจ เราใส่ใจในการบริการลูกค้าและการควบคุมคุณภาพของสินค้าที่เราจัดหา. ทีมงานของเราพร้อมให้ความช่วยเหลือในการเลือกสินค้า, ให้คำปรึกษาทางเทคนิค, และตอบสนองต่อคำขอพิเศษของลูกค้า.</li>
                <li>การพัฒนาและนวัตกรรมตลอดเวลา อุตสาหกรรมสิ่งทอเป็นอุตสาหกรรมที่เปลี่ยนแปลงอย่างรวดเร็ว, เราเข้าใจความสำคัญของการนำเสนอผลิตภัณฑ์ที่ตอบสนองต่อนวัตกรรมใหม่ ๆ. เราทุ่มเทในการพัฒนาผลิตภัณฑ์ที่ทันสมัยและตรงต่อความต้องการของตลาด.</li>
                <li>สินค้าที่ปรับได้ เราเข้าใจว่าความต้องการของลูกค้าอาจแตกต่างกัน, ดังนั้นเราให้บริการสินค้าที่สามารถปรับเปลี่ยนได้ตามความต้องการของลูกค้า. ไม่ว่าจะเป็นการปรับให้เข้ากับขนาดหรือการปรับแต่งเพื่อให้ตอบสนองต่อความต้องการของลูกค้า.</li>
              </ol>
              <p>เรายินดีที่จะเป็นพันธมิตรในการส่งเสริมธุรกิจของคุณ และให้ความมั่นใจว่าสินค้าที่คุณได้รับจากเราจะมีคุณภาพและความเชื่อ</p>
            </Col>
          </Row>
        </Container>
      <Formfooter />
      <Footer />
    </Containers>
    )
  }
}

export const productCatagoriesQuery = graphql`
  query productCatagoriesQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { key: { eq: "product" }}}
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`